// NODE MODULES
import "core-js/stable";
// import "regenerator-runtime/runtime";
// import 'babel-polyfill';
// import 'gsap';
// import 'gsap/ScrollToPlugin';


// Object fit polyfill
// import objectFitImages from 'object-fit-images';
// window.objectFitImages = objectFitImages;

// Flickity
// import Flickity from 'flickity';
// window.Flickity = Flickity;

// COMMON
import './common/bind-all';
import './common/get-url-file-extension';
import './common/is-array';
import './common/is-object';

// DOM
import './dom/closest';
import './dom/delegate';
import './dom/findDOM';
import './dom/get-element-center';
import './dom/get-window-position';
import './dom/is-element-visible';
import './dom/create-element';

import './dom/background-clip-polyfill';
// import './dom/smooth-scroll';
import './dom/split-text';
import './dom/swipe';

// MATH
import './math/clamp';
import './math/lerp';
import './math/loop-index';
import './math/map';
import './math/normalize';
import './math/smoothstep';
import './math/random-float';
import './math/random-int';
import './math/rgb-to-hex';
import './math/hex-to-rgb';
import './math/is-even';
import './math/is-odd';

// GEOMETRY
import './geometry/angle';
import './geometry/diagonal';
import './geometry/distance';
import './geometry/to-degrees';
import './geometry/to-radians';

// STRING
import './string/capitalize';

// // POLYFILL
// import './polyfill'
