/**
 * Return an element
 *
 * @param  {string}  selector
 * @param  {Element} container
 * @return {Element}
 */
function findDOM(selector, container = document) {
    return container.querySelector(selector);
}

window.findDOM = function(selector, container) {
    return findDOM(selector, container);
};

/**
 * Return an array of elements
 *
 * @param  {string}  selector
 * @param  {Element} container
 * @return {Array}
 */
function findAllDOM(selector, container = document) {
    return Array.prototype.slice.call(container.querySelectorAll(selector), 0);
}

window.findAllDOM = function(selector, container) {
    return findAllDOM(selector, container);
};
