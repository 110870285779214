/**
 * Check if value is an Object
 *
 * @param  {any} value Value
 * @return {boolean}   True if value is an Object, false otherwise
 */
function isObject(value) {
    return (value === Object(value) && !isArray(value));
}  

window.isObject = function(value) {
    return isObject(value);
};
