/**
 * Convert a radians value into degrees
 *
 * @param  {number} radians Radians
 * @return {number}         Degrees
 */
function toDegrees(radians) {
    return radians * 180 / Math.PI;
}

window.toDegrees = function(radians) {
    return toDegrees(radians);
};
