/**
 * Check if an element is visible
 *
 * @param  {number}  element
 * @param  {boolean} xCheck  Define if the check needs to be done on the x axis
 * @param  {boolean} yCheck  Define if the check needs to be done on the y axis
 * @return {boolean}         True if the given element is visible, false otherwise
 */
function isElementVisible(element, xCheck = false, yCheck = true) {
    const { width, height, top, right } = element.getBoundingClientRect();
    let x = true;
    let y = true;

    if (xCheck) x = ((right + width) > 0) && (right < (window.innerWidth || document.documentElement.clientWidth));
    if (yCheck) y = ((top + height) > 0) && (top < (window.innerHeight || document.documentElement.clientHeight));

    return (x && y);
}

window.isElementVisible = function(element, xCheck, yCheck) {
    return isElementVisible(element, xCheck, yCheck);
};
