/**
 * Convert a degrees value into radians
 *
 * @param  {number} degrees Degrees
 * @return {number}         Radians
 */
function toRadians(degrees) {
    return degrees * Math.PI / 180;
}

window.toRadians = function(degrees) {
    return toRadians(degrees);
};
