/**
 * Split text
 *
 * @param  {Element}  els
 * @param  {string}   spanClass
 * @return {Element|Array<Element>}
 */
function splitText(els, spanClass = '') {
    let $splitedTexts = [];
  
    if (!els.length)
        els = [els];
  
    for (let i = 0, j = els.length; i < j; i++) {
        const el = els[i];
        const text = el.innerHTML;
        const splittedText = text.split('');
  
        let markup = [];
        let isTag = false;
        let tag = '';
  
        for (let k = 0, l = splittedText.length; k < l; k++) {
            if (splittedText[k] === ' ')
                splittedText[k] = '\u00A0';
  
            if (splittedText[k] === '<')
                isTag = true;
            else if (splittedText[k - 1] === '>')
                isTag = false;
  
            if (isTag) {
                tag += splittedText[k];
            } else {
                let item = '';
                if (tag !== '') {
                    item = tag + `<span class="${spanClass}">${splittedText[k]}</span>`;
                    tag = ''
                } else {
                    item = `<span class="${spanClass}">${splittedText[k]}</span>`;
                }
                markup.push(item);
            }
        };
  
        el.innerHTML = markup.join('');
  
        $splitedTexts.push(el.querySelectorAll('span'));
    }
  
    if ($splitedTexts.length > 1)
        return $splitedTexts;
    else
        return $splitedTexts[0];
}

window.splitText = function(els, spanClass = '') {
    return splitText(els, spanClass);
};
