class BackgroundClipPolyfill {
    constructor(params) {
        this.hasBackgroundClip = this._hasBackgroundClip();
        this._fontSize = parseInt(window.getComputedStyle(params.textElement, null).getPropertyValue('font-size').split('px')[0]);

        if (!this.hasBackgroundClip) {
            const backgroundImage = new Image();

            backgroundImage.addEventListener('load', event => {
                const svg = this._createSVG({
                    id: params.patternID,
                    url: params.patternURL,
                    className: params.className,
                    width: event.target.width,
                    height: event.target.height,
                    text: params.textElement.innerHTML
                });

                params.textElement.style.height = 0;
                params.textElement.style.overflow = 'hidden';
                params.textElement.parentNode.appendChild(svg);
            });
            backgroundImage.src = params.patternURL;
        }
    }

    _hasBackgroundClip() {
        return document.body.style.webkitBackgroundClip != undefined;
    }

    _createSVG(params) {
        const svg = this._createSvgElement('svg');
        const pattern = this._createSvgElement('pattern');
        const image = this._createSvgElement('image');

        // Add attributes to elements
        this._addAttributes(pattern, {
            id: params.id,
            patternUnits: 'userSpaceOnUse',
            width: params.width,
            height: params.height
        });

        this._addAttributes(image, {
            width: params.width,
            height: params.height
        });
        image.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', params.url);

        // Add elements to pattern
        pattern.appendChild(image);

        // Add elements to SVG
        svg.appendChild(pattern);

        const texts = params.text.split('<br>');
        for (let i = 0, j = texts.length; i < j; i++) {
            const text = texts[i];
            const textElement = this._createSvgElement('text');

            this._addAttributes(textElement, {
                x: '50%',
                y: (this._fontSize * i) + this._fontSize,
                class: params.className,
                style: 'fill:url(#' + params.id + ');'
            });

            // Set text
            textElement.textContent = text;

            svg.appendChild(textElement);
        }

        svg.style.height = (texts.length * this._fontSize) + 'px';

        return svg;
    }

    _createSvgElement(tagName) {
        return document.createElementNS('http://www.w3.org/2000/svg', tagName);
    }

    _addAttributes(el, attributes){
        for (let key in attributes) {
            if (attributes.hasOwnProperty(key)) {
                el.setAttribute(key, attributes[key]);
            }
        }
    }
}

window.BackgroundClipPolyfill = BackgroundClipPolyfill;
