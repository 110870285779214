/**
 * Clamp a value between two bounds
 *
 * @param  {number} value Value to clamp
 * @param  {number} min   Minimum boundary
 * @param  {number} max   Maximum boundary
 * @return {number}       Clamped value
 */
function clamp(value, min, max) {
    if (value < min)
        return min;
    else if (value > max)
        return max;
    return value;
}

window.clamp = function(value, min, max) {
    return clamp(value, min, max);
};
