/**
 * Smooth a value using cubic Hermite interpolation
 *
 * @param  {number} value Value to smooth
 * @param  {number} min   Minimum boundary
 * @param  {number} max   Maximum boundary
 * @return {number}       Normalized smoothed value
 */
function smoothstep(value, min, max) {
    const x = Math.max(0, Math.min(1, (value - min) / (max - min)));
    return x * x * (3 - 2 * x);
}

window.smoothstep = function(value, min, max) {
    return smoothstep(value, min, max);
};
