/**
 * Return the distance beetween two points
 *
 * @param  {number} x1
 * @param  {number} y1
 * @param  {number} x2
 * @param  {number} y2
 * @return {number} Distance
 */
function distance(x1, y1, x2, y2) {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return Math.sqrt(dx * dx + dy * dy);
}

window.distance = function(x1, y1, x2, y2) {
    return distance(x1, y1, x2, y2);
};
