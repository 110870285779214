class Swipe {
    constructor(container, callback, sensibility = 30, params = ['left', 'right']) {
        this._container = container;
        this._callback = callback;
        this._sensibility = sensibility;
        this._params = params;

        this._swipe = {
            startX: 0
        };

        bindAll(this, [
            '_touchstartSwipe',
            '_touchendSwipe'
        ]);

        this._bind();
    }

    _bind() {
        this._container.addEventListener('touchstart', this._touchstartSwipe);
        this._container.addEventListener('touchend', this._touchendSwipe);
    }

    unbind() {
        this._container.removeEventListener('touchstart', this._touchstartSwipe);
        this._container.removeEventListener('touchend', this._touchendSwipe);
    }

    _touchstartSwipe(event) {
        this._swipe.startX = event.changedTouches[0].pageX;
    }

    _touchendSwipe(event) {
        const x = event.changedTouches[0].pageX;
        if ((x > this._swipe.startX) && ((x - this._swipe.startX) > this._sensibility))
            this._callback(this._params[0]);
        else if ((x < this._swipe.startX) && ((x - this._swipe.startX) < -this._sensibility))
            this._callback(this._params[1]);
    }
}

window.Swipe = Swipe;
