/**
 * Create a simple dom element
 *
 * @param {string} tag - Element tag
 * @param {Object=} parameters - Element parameters
 * @param {string=} parameters.className - element classes
 * @param {string=} parameters.text - text classes
 * @param {Array<{name: string, value: string}>=} parameters.attributes - Element attributes array
 * @return {HTMLElement}
 */
function createHTMLElement(tag, {
        className,
        text,
        attributes
    } = {}) {
    const element = document.createElement(tag);

    if(className)
        element.className = className;

    if(text) {
        const textNode = document.createTextNode(text);
        element.appendChild(textNode);
    }

    if(attributes) {
        for (const key in attributes) {
            element.setAttribute(key, attributes[key]);
        }
    }

    return element;
}

window.createHTMLElement = createHTMLElement;
