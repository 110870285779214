/**
 * Return the angle from a point to another
 *
 * @param  {number} x1
 * @param  {number} y1
 * @param  {number} x2
 * @param  {number} y2
 * @return {number} Angle
 */
function angle(x1, y1, x2, y2) {
    return Math.atan2(y2 - y1, x2 - x1);
}

window.angle = function(x1, y1, x2, y2) {
    return angle(x1, y1, x2, y2);
};
